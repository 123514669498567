.addressblock:first-of-type {
    margin-bottom: 78px;
}

.addressblock {
    margin-bottom: 56px;
    margin-right: 16px;
}

.addressblock h2 {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 42px;
}

.addressblock p {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
    margin-bottom: 25px;
}

.addressblock br {
    margin: 0;
}

.addressblock .letstalk {
    color: #F4F4F4;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 45px;
}

.addressblock .letstalk a {
    font-size: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

form input {
    height: 70px;
    border-radius: 3px;
    background-color: rgba(216,216,216,0.1);
    border: none;
    width: 100%;
}

form input[type="text"]:focus, form input[type="email"]:focus, form textarea:focus {
    border-bottom: 2px solid #F4F4F4;
    outline: none;
}

form textarea {
    height: 206px;
    border-radius: 3px;
    background-color: rgba(216, 216, 216, 0.1);
    border: 0 solid;
    width: 100%;
    padding-top: 20px;
    resize: none;
}

label {
    margin: 8px 0;
    position: relative;
    display: inline-block;
}

.addressblock span, .addresscolumn span {
    height: 43px;
    opacity: 0.5;
    color: #F4F4F4;
    font-size: 20px;
    line-height: 30px;
    padding: 15px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 5px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.gm-style span {
    height: auto;
    padding: 0;
    opacity: 1.0;
    line-height: normal;
    position: inherit;
    top: 0;
    color: rgb(68, 68, 68);
    cursor: pointer;
}

input, textarea {
    padding: 10px;
    color: #F4F4F4;
    font-size: 20px;
    line-height: 30px;
    font-family: "Roboto", sans-serif;
}

.addressblock input:focus + span, .addressblock input:not(:placeholder-shown) + span {
    opacity: 1;
    transform: scale(0.7) translateY(-60%) translateX(-20%);
}

.addressblock textarea:focus + span, .addressblock textarea:not(:placeholder-shown) + span {
    opacity: 1;
    transform: scale(0.7) translateY(-60%) translateX(-20%);
}

/* For IE Browsers*/
.addressblock input:focus + span, .addressblock input:not(:-ms-input-placeholder) + span,
.addressblock textarea:focus + span, .addressblock textarea:not(:-ms-input-placeholder) + span {
    opacity: 1;
    transform: translateY(-25%) translateX(-30px);
    font-size: 14px;
    line-height: 30px;
}

form button[type="submit"] {
    height: 60px;
    width: 100%;
    color: #F4F4F4;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    background-color: #7268D8;
    border: 0;
    border-radius: 50px;
    margin-top: 24px;
}

form button[type="submit"]:hover {
    background-color: #4C42B9;
}

form button[type="submit"]:active {
    background-color: #382F90;
    outline: none;
}

form p#error {
    display: none;
    background-color: #EF6650;
    border-radius: 29px;
    margin: 20px auto 0 auto;
    width: 40%;
    padding: 10px;
    text-align: center;
}

.message--error {
    color: #EF6650;
}

@media screen and (min-width: 481px) {
    .addressblock {
        margin-right: 32px;
    }
}

@media screen and (min-width: 1440px) {
    .addressblock {
        display: flex;
        padding-left: 13%;
        padding-right: 13%;
        margin-right: 0;
    }

    .addressblock h2 {
        flex: 0 0 30%;
    }

    .addressblock>div {
        flex: 0 80%;
    }

    .addressblock:last-of-type>div {
        padding-right: 14%;
    }
}