br {
    display: block;
    margin: 20px 0;
    content: " ";
}

.description {
    margin-bottom: 78px;
}

.description.collab {
    margin-right: 16px;
}

.demoblock.collab h2 {
    display: none;
}

.projectitem {
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding: 0 16px 30px 0;
}

.projectitem ~ .projectitem {
    padding-top: 30px;
}

.projectitem:last-of-type {
    padding-bottom: 0;
}

.projectitem h3 {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 5px;
}

.projectitem .description {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.projectitem .description p {
    color: #9B9B9B;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
    margin-top: 0;
}

.circle {
    height: 6px;
    width: 6px;
    background-color: #FFFFFF;
    border-radius: 100%;
    margin: 0 10px;
}

.projectitem p {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
    margin-top: 15px;
}

.partner-description {
    display: flex;
}

.partner-description p:first-of-type {
    margin-right: 5px;
}

.partner a {
    font-weight: bold;
}

@media screen and (min-width: 481px) {
    .description.collab {
        margin-right: 20%;
    }

    .projectitem {
        padding-right: 32px;
    }
}

@media screen and (min-width: 1440px) {
    .description.collab {
        display: flex;
        margin-right: 0;
    }

    .demoblock.collab h2 {
        display: block;
    }

    .description.collab p {
        margin-right: 25%;
    }

    .projectitem {
        padding-right: 13%;
    }
}