footer {
    padding-top: 40px;
}

footer a {
    color: #E0B12B;
}

.padded-footer {
    padding-left: 16px;
    padding-right: 16px;
    display: grid;
    grid-template-columns: 1fr;
}

.padded-footer>div {
    margin-bottom: 50px;
}

.padded-footer #logo {
    width: 163px;
    margin-bottom: 42px;
}

.padded-footer p {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
}

.address-information p:last-of-type {
    margin-top: 28px;
}

footer .small-paragraph {
    color: #9B9B9B;
    font-size: 14px;
    margin-bottom: 22px;
}

.quick-links p {
    margin-bottom: 18px;
}

.social-media>div {
    display: flex;
}

.social-media a {
    margin-right: 32px;
}

.social-media-logo {
    height: 40px;
    width: 40px;
}

.social-media-logo>div {
    height: 100%;
    width: 100%;
}

.social-media-logo svg {
    display: block;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}

.alma-mater div, .alma-mater a {
    width: 123px;
    height: 123px;
}

.alma-mater a {
    display: block;
}

.alma-mater svg {
    height: 100%;
    width: 100%;
}

.footer-patterns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
}

.footerLeftPattern, .footerRightPattern {
    display: none;
}

.footerMobilePattern svg {
    display: block;
}

@media screen and (min-width: 481px) {
    .padded-footer {
        padding-left: 32px;
        padding-right: 32px;
        /*display: flex;
        flex-flow: row wrap;*/
        grid-template-columns: repeat(3, minmax(130px, 1fr));
        grid-gap: 10px;
    }

    .padded-footer>div {
        margin-bottom: 25px;
    }

    .padded-footer #logo {
        width: 100%;
        max-width: 163px;
        padding-right: 10px;
    }

    .address-information, .quick-links, .alma-mater {
        order: 1;
        /*flex: 0 33%;*/
    }

    .social-media {
        order: 2;
        /*flex: 0 100%;*/
        text-align: center;
        grid-column: 1 / -1;
    }

    .social-media>div {
        width: fit-content;
        margin: 0 auto;
    }

    .social-media a:last-of-type {
        margin-right: 0;
    }

    .footer-patterns {
        padding: 0;
        width: 100%;
        margin-top: 40px;
        align-items: flex-end;
    }

    .footerMobilePattern {
        display: none;
    }

    .footerLeftPattern, .footerRightPattern {
        display: initial;
        /*width: 270px;*/
        flex: 0 35%;
        max-width: 800px;
    }

    .footerLeftPattern svg, .footerRightPattern svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    .footer-patterns p {
        flex: 0 25%;
        text-align: center;
    }
}

@media screen and (min-width: 769px) {
    .padded-footer {
        padding-left: 13%;
        padding-right: 13%;
        grid-template-columns: repeat(4, minmax(120px, 1fr));
        grid-gap: 20px;
    }

    .padded-footer>div {
        order: 1;
        /*flex: 0 25%;*/
    }

    .social-media {
        text-align: left;
        grid-column: auto;
    }

    .social-media>div {
        margin: 0;
    }

    .footer-patterns {
        margin-top: 0;
    }
}