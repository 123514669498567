#BranchMenu {
    /*height: calc(100% - 100px);*/
    display: flex;
    flex-direction: column;
}

.Branch {
    flex: 0 25%;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding: 16px;
}

.Branch a {
    display: flex;
    align-items: center;
    height: 100%;
}

.BranchLogo {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 16px;
    padding: 14px;
}

.BranchLogo>div {
    width: 100%;
    height: 100%;
}

.BranchLogo svg {
    display: block;
    width: 100%;
    height: 100%;
}

.Branch .description {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: space-between;
    flex-basis: 100%;
}

.Branch .description h1 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    width: 90%;
}

.Branch .description .arrow {
    margin-right: 0;
    margin-left: 10px;
}

.scrollbutton {
    display: none;
}

.main-page button {
    height: 50px;
    min-width: 178px;
    border-radius: 39px;
    background-color: rgba(216,216,216,0.1);
    margin: 31px auto 0 auto;
    border: 0;
    display: block;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
}

.button-href {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    padding-right: 20px;
    padding-left: 20px;
}

.button-href .arrow {
    width: 13px;
    margin-left: 16px;
    margin-right: 0;
}

.button-href .arrow svg {
    display: block;
}

.button-href:hover, button:hover .button-href {
    color: #282828;
}

.button-href:hover path, button:hover .button-href path {
    fill: #282828 !important;
}

button:hover {
    background-color: #D8D8D8;
    color: #282828;
}

button:active {
    opacity: 0.8;
}

button:disabled {
    opacity: 0.4;
}

.main-page .smallnews:last-of-type {
    margin-bottom: 0;
}

#grid {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 22px;
    flex-grow: 1;
}

.main-page {
    border-bottom: 0;
    padding-left: 16px;
}

.flexdiv.main-page {
    margin-bottom: 74px;
}

.App.main-page:last-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 0;
}

.peopleblock {
    margin-right: 16px;
    margin-left: 16px;
}

.peopleblock.main-page {
    padding-bottom: 40px;
    margin-left: 0;
}

.people {
    display: block;
    background-image: linear-gradient(195.53deg, rgba(40, 40, 40, 0) 0%, rgba(40, 40, 40, 0.85) 59.51%, #282828 100%), url('./media/img/adult-brainstormin.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 390px;
}

.BranchSmall {
    height: 100%;
    width: 100%;
    white-space: normal;
}

.BranchSmall h3 {
    font-weight: normal;
}

.Branch.without-ripple {

}

@media screen and (min-width: 481px) {
    .Branch {
        padding-left: 32px;
        padding-right: 32px;
    }

    .BranchLogo {
        width: 110px;
        height: 110px;
        padding: 26px;
        margin-right: 32px;
    }

    .Branch .description h1 {
        font-size: 24px;
        line-height: 32px;
    }

    .main-page {
        padding-left: 32px;
    }

    .react-multi-carousel-list.main-page {
        padding-left: 0;
        margin-bottom: 40px;
    }

    .main-page .addresscolumn p.big a {
        font-size: 16px;
    }

    #grid {
        margin-top: 80px;
    }

    #grid .title {
        margin-top: 0;
        /*margin-bottom: 18px;*/
        font-size: 40px;
        line-height: 44px;
    }

    #grid .demoitem:nth-child(1) {
        padding-top: 8px;
    }

    #grid .people {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 1440px) {
    .vertical {
        writing-mode: vertical-lr;
    }

    #grid {
        margin-top: 80px;
    }

    #grid>div {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-auto-rows: min-content;
        grid-gap: 32px;
        margin-left: 13%;
    }

    .main-page {
        padding-left: 0;
    }

    #grid .title {
        margin: 0;
        padding: 0;
        font-size: 80px;
        line-height: 70px;
        /*padding-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 32px;*/
        /*flex: 0 0 10%;
        max-width: 30%;*/
    }

    .main-page .demoitem {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .main-page .demoitem .arrow {
        margin-right: 13%;
    }

    .flexdiv.main-page {
        margin-right: 0;
        padding-right: 0;
        padding-left: 0;
    }

    .flexdiv.main-page>div {
        margin-right: 13%;
    }

    .flexdiv.main-page button {
        margin-right: 0;
    }

    #grid .people {
        margin: 0;
    }

    .peopleblock.main-page {
        padding-bottom: 80px;
        margin-right: 0;
    }

    .peopleblock.main-page .vertical {
        writing-mode: vertical-rl;
        display: flex;
        align-items: flex-end;
    }

    .moveDown {
        margin-top: 70px;
        padding-right: 13%;
        margin-right: 13%;
    }

    .peopletitle {
        margin-top: -200px !important;
    }

    .peopleblock.main-page button {
        margin: 31px auto 0 0;
    }

    .react-multi-carousel-list.main-page {
        margin: 0 !important;
        padding-bottom: 55px;
        display: flex !important;
    }

    .demoblock.main-page button {
        margin-right: 13%;
    }

    .addressblock.main-page {
        padding: 0;
    }

    .addressblock.main-page h3 {
        padding-left: 0;
        margin-right: 0;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .addressblock.main-page .addresscolumn {
        margin-right: 13%;
    }

    .addressblock.main-page p, .main-page .addresscolumn p.big a {
        font-size: 20px;
        line-height: 28px;
    }
}

@media screen and (min-width: 768px) {
    #BranchMenu {
        /*height: calc(100vh - 130px);*/
    }
}

@media screen and (min-width: 860px) {
    #BranchMenu {
        flex-direction: row;
        height: calc(100vh - 230px);
        min-height: min-content;
    }

    .Branch {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        padding: 32px;
        position: relative;
        transition: all 0.2s ease;
        overflow: hidden;
        cursor: pointer;
    }

    .Branch a {
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    .BranchLogo {
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 100%;
        padding: 32px 0;
    }

    .BranchLogo>div {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        vertical-align: middle;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        height: 0;
    }

    .BranchLogo svg {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        padding: 23%;
        height: 100%;
        width: 100%;
    }

    .BranchLogo.white, .BranchLogo.red, .BranchLogo.purple, .BranchLogo.yellow {
        background-color: transparent !important;
    }

    .ink {
        display: block;
        position: absolute;
        border-radius: 100%;
        transform:scale(0);
        z-index: -1000;
    }

    .purple .ink {
        background-color: #7268D8;
    }

    .red .ink {
        background-color: #EF6650;
    }

    .yellow .ink {
        background-color: #E0B12B;
    }

    .white .ink {
        background-color: #FFFFFF;
    }

    .ripple.white h1 {
        color: #282828;
        transition: color 0.65s;
    }

    .ripple.white .arrow path {
        fill: #282828 !important;
        transition: fill 0.65s;
    }

    .animate {
        animation: ripple 0.65s linear;
        animation-fill-mode: forwards;
    }

    @keyframes ripple {
        100% {
            opacity: 1.0;
            transform: scale(3);
        }
    }

    /*.BranchLogo {
        margin-right: 0;
        flex: 0 70%;
        width: 100%;
        background: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 20px;
        border: 1px solid red;
        border-radius: 0%;
    }*/

    .BranchLogo.purple>div {
        background-color: #7268D8;
    }

    .BranchLogo.red>div {
        background-color: #EF6650;
    }

    .BranchLogo.yellow>div {
        background-color: #E0B12B;
    }

    .BranchLogo.white>div {
        background-color: #FFFFFF;
    }

    .Branch .description {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        min-height: 120px;
        flex-basis: 30%;
        max-height: 160px;
        justify-content: space-between;
    }

    #BranchMenu .description h1 {
        width: 100%;
    }

    .Branch .description h1 {
        font-size: 20px;
        line-height: 28px;
    }

    .Branch .description .arrow {
        margin-left: 0;
        margin-top: 10px;
    }

    .scrollbutton {
        display: block;
        height: 46px;
        width: 32px;
        border: 2px solid #FFFFFF;
        border-radius: 20px;
        margin: 32px auto 65px auto;
    }

    .scrollbutton .circle {
        height: 6px;
        width: 6px;
        background-color: #FFFFFF;
        border-radius: 50%;
        margin: 8px 11px;
        animation: scroll 2s linear infinite;
        -webkit-animation: scroll 2s linear infinite; /* Safari 4.0 - 8.0 */
        -moz-animation: scroll 2s linear infinite;
    }
}

@media screen and (min-width: 1024px) {
    /*.BranchLogo>div {
        height: 18vmax;
        width: 18vmax;
        padding: 3.5rem;
    }*/

    .Branch .description h1 {
        font-size: 24px;
        line-height: 30px;
    }
}

@media screen and (min-width: 2560px) {
    /*.BranchLogo>div {
        padding: 100px;
    }*/

    .Branch .description {
        max-height: 200px;
    }

    .Branch .description h1 {
        font-size: 50px;
        line-height: 58px;
    }

}

@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(18px);
    }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(18px);
    }
}

@-moz-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(18px);
    }
}
