.flexdiv {
    margin-right: 16px;
}

.newsfilter {
    height: 36px;
    width: 118px;
    margin-bottom: 45px;
    z-index: 1;
}

.newsfilter-control {
    border-radius: 39px;
    background-color: #3D3D3D;
    border: none;
    z-index: 100;
    box-shadow: none !important;
    padding: 8px 35px 8px 18px;
}

.newsfilter-placeholder {
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0.7px;
}

.newsfilter-menu {
    border-radius: 0 0 21px 21px;
    background-color: #3D3D3D;
    border: none;
    z-index: 10;
    margin-top: -15px;
    padding-top: 15px;
}

.newsfilter-menu .Dropdown-option {
    color: #FFFFFF;
    padding: 12px 18px;
    letter-spacing: 0.7px;
    font-size: 16px;
}

.newsfilter-arrow {
    border-color: #FFFFFF transparent transparent;
    right: 14px;
}

.is-open .newsfilter-arrow {
    border-color: transparent transparent #FFFFFF;
}

.newscolumn {
    /*display: flex;
    flex-flow: column;*/
    display: grid;
}

.smallnews {
    margin-bottom: 45px;
}

.smallnews img, .article img {
    width: 100%;
    margin-bottom: 15px;
}

.smallnews h3, .smallnews h3 a {
    color: #E0B12B;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
}

.smallnews a:hover {
    color: #EF6650;
}

.smallnews .date, .articlecontent .date  {
    color: rgba(216,216,216,0.8);
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
    margin-bottom: 25px;
}

.smallnews .excerpt {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
    word-break: break-word;
}

@media screen and (min-width: 768px) {
    .flexdiv {
        margin-right: 32px;
    }

    .newsfilter {
        list-style-type: none;
        display: flex;
        height: auto;
        width: auto;
    }

    .newsfilter li {
        color: #F4F4F4;
        font-size: 16px;
        letter-spacing: 0.7px;
        line-height: 24px;
        margin-right: 24px;
    }

    .newsfilter .active-news {
        color: #E0B12B;
    }

    .newscolumn {
        /*flex-flow: row wrap;*/
        grid-template-columns: repeat(2, minmax(40%, 1fr));
        grid-gap: 32px;
    }

    /*.smallnews {
        flex: 0 calc(50% - 17px);
    }*/

    /*.smallnews:nth-of-type(odd) {
        margin-right: 17px;
    }

    .smallnews:nth-of-type(even) {
        margin-left: 17px;
    }*/

    .smallnews h3 {
        font-size: 24px;
        line-height: 32px;
    }

    .imagecontainer {
        max-height: 50%;
        height: 50%;
        width: 100%;
    }

    .smallnews img {
        object-fit: cover;
        margin-bottom: 0;
        padding-bottom: 15px;
        height: 100%;
    }
}

@media screen and (min-width: 1440px) {
    .flexdiv {
        display: flex;
        justify-content: space-between;
        padding-left: 13%;
        padding-right: 13%;
        margin-right: 0;
    }

    .newsfilter {
        flex-direction: column;
        flex: 0 20%;
        margin-right: 32px;
    }

    .newsfilter li {
        margin-bottom: 16px;
        cursor: pointer;
        width: fit-content;
    }

    .newscolumn {
        flex: 0 80%;
        grid-gap: 100px;
        margin-bottom: 40px;
    }

    .smallnews {
        margin-bottom: 0;
    }

    /*.smallnews {
        flex: 0 calc(50% - 61px);
    }

    .smallnews:nth-of-type(odd) {
        margin-right: 61px;
    }

    .smallnews:nth-of-type(even) {
        margin-left: 61px;
    }*/

    .flexdiv.main-page {
        justify-content: flex-start;
    }
}

/* Article page */
.article {
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 48px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding-bottom: 72px;
}

.article h2 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 10px;
}

.article .content {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
}

.article .content>p {
    margin-bottom: 15px;
}

.backbutton img {
    mask-image: none;
    -webkit-mask-image: none;
}

@media screen and (min-width: 481px) {
    .article {
        padding-left: 32px;
        padding-right: 32px;
    }

    .article h2 {
        font-size: 30px;
        line-height: 38px;
    }

    .article img {
        margin-bottom: 45px;
        max-width: 100%;
        max-height: 400px;
        object-fit: cover;
    }

    .article>div {
        display: flex;
    }

    .backbutton {
        flex: 0 0 30%;
        max-width: 180px;
    }

    .backbutton a {
        width: fit-content;
        width: -moz-fit-content;
        display: flex;
        align-items: center;
    }

    .backbutton a:hover {
        color: #E0B12B;
    }

    .backbutton a:hover img {
        transform: translateX(-8px);
        transition-duration: .4s;
    }

    .backbutton a:active p {
        transform: translateX(-8px);
        transition-duration: .4s;
    }

    .backbutton img {
        width: 22px;
        margin-right: 8px;
        margin-bottom: 0;
    }

    .backbutton p {
        letter-spacing: 0.7px;
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {
    .article {
        padding-right: 13%;
        padding-left: 13%;
    }

    .backbutton {
        flex: 0 0 250px;
    }

}
