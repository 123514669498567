ul {
    list-style-type: none;
}

#university-logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 36px;
    padding-top: 4px;
    padding-bottom: 4px;
}

#university-logo>div {
    height: 100%;
    width: 100%;
}

#university-logo svg {
    display: block;
    height: 100%;
    width: 100%;
}

nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
}

nav.active {
    /*min-height: calc(100vh - 36px);
    height: 100%;*/
    flex-grow: 1;
    justify-content: space-between;
}

.menu {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.menu li {
    white-space: nowrap;
    cursor: pointer;
}

.logo {
    width: 131px;
    padding: 22px 16px;
}

.toggle {
    order: 1;
    padding: 22px 16px;
}

.item {
    display: none;
    width: 100%;
    text-align: center;
    padding: 12px 16px;
    order: 2;
}

.item a, .dropdown, .item {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 28px;
}

.item.active {
    display: block;
}

.item ~ .item {
    border: none;
}

.item {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdown svg {
    margin-left: 10px;
}

.dropdown-content {
    display: none;
}

.dropdown-content li {
    text-align: center;
    padding: 10px 16px;
}

.dropdown-content li:last-of-type {
    padding-bottom: 0;
}

.dropdown-content li a {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
}

.dropdown-content.active {
    display: block;
}

.dropdown-content .arrow {
    display: none;
}

.language-change {
    display: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 27px 16px;
    justify-content: center;
}

nav.active .language-change {
    display: flex;
}

.item.lang-change {
    width: auto;
    border-top: none;
    padding: 0 10px;
    opacity: 0.6;
}

.sticky-wrapper {
    position: relative;
    /*height: 100px;*/
}

.sticky .sticky-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1003;
    background-color: #282828;
}

.sticky-inner.active {
    height: 100%;
    overflow-y: scroll;
}

/*.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #282828;
    z-index: 2;
}*/

.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #282828;
}

@media screen and (min-width: 768px) {
    #university-logo {
        height: 46px;
    }

    .logo {
        padding: 0 0 0 32px;
        width: 161px;
    }

    .toggle {
        padding-right: 32px;
    }

    .toggle svg {
        /*width: 18px !important;*/
        height: auto;
    }
}

@media screen and (min-width: 1024px) {
    nav {
        flex-direction: row;
        position: relative;
    }

    .menu {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        flex: 0 100%;
        padding-right: 16px;
    }

    .menu li, .menu li a, .lang-change {
        font-size: 16px;
        line-height: 19px;
    }

    .logo {
        order: 0;
        margin-right: auto;
    }

    .item, .lang-change {
        order: 1;
        display: table;
        width: auto;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    .item {
        margin-left: 16px;
        margin-right: 16px;
    }

    .item a {
        display: inline-flex;
        align-items: center;
        height: 100%;
    }

    .item:hover, .item:hover>a, .lang-change:hover {
        color: rgba(255, 255, 255, 0.6);
    }

    .item:focus, .lang-change:focus {
        color: rgba(255, 255, 255, 1.0);
    }

    .toggle {
        display: none;
    }

    .dropdown svg {
        display: none;
    }

    .language-change {
        display: flex;
        border-top: 0;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        margin-top: 0;
        align-items: center;
        padding-right: 32px;
    }

    .item.lang-change {
        margin-right: 8px;
        margin-left: 8px;
        padding: 0;
    }

    .current {
        border-bottom: 4px solid #7268D8;
        transition: border-bottom .3s ease-in-out;
        transform: translateY(2px);
    }

    .menu .dropdown {
        line-height: 73px;
    }

    .dropdown:focus {
        border-bottom: 4px solid #7268D8;
        /*transform: translateY(2px);*/
        transition: border-bottom .3s ease-in-out;
    }

    .current.dropdown {
        transform: none;
        border-bottom: 4px solid #7268D8;
        transition: border-bottom .3s ease-in-out;
        line-height: 69px;
    }

    /*.dropdown:hover {
        border-bottom: 4px solid #7268D8;
        transition: border-bottom .3s ease-in-out;
        transform: translateY(2px);
        line-height: 69px;
    }*/

    .dropdown-content li {
        list-style: none;
        flex: 1;
        display: flex;
        color: #FFFFFF;
        font-size: 30px;
        font-weight: bold;
        line-height: 38px;
        margin-left: 50%;
        align-items: center;
        padding: 17px 0;
        transform: translateY(0px);
    }

    .dropdown-content li:last-of-type {
        padding: 17px 0;
    }

    .dropdown-content li>a {
        font-size: 30px;
        font-weight: bold;
        line-height: 38px;
    }

    .dropdown:focus > .dropdown-content {
        display: flex;
    }

    .dropdown-content {
        position: absolute;
        display: none;
        left: 0;
        top: calc(100% + 1px);
        width: 100%;
        border: 1px solid #666666;
        border-top: 0;
        flex-direction: column;
        background-color: #282828;
        opacity: 1.0;
        padding: 30px;
        transform: translateY(0px);
        z-index: 1000;
    }

    .dropdown-content .arrow {
        display: inline-block;
        /*margin-right: 14%;*/
    }

    .dropdown-content a {
        flex: 1;
        text-align: left;
    }

    #BranchMenuSmall {
        display: none;
        flex-direction: row;
        padding: 0;
        z-index: 1200;
        height: 325px;
    }

    #BranchMenuSmall.active {
        display: flex;
    }

    #BranchMenuSmall .Branch {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
    }

    #BranchMenuSmall a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    #BranchMenuSmall .BranchLogo {
        height: 80px;
        width: 80px;
        flex: 0 0 auto;
        padding: 0;
        margin: 0 0 32px 0;
    }

    #BranchMenuSmall svg {
        display: block;
        height: 100%;
        width: 100%;
    }

    #BranchMenuSmall .BranchLogo>div {
        height: 100%;
        width: 100%;
        padding: 17px;
    }

    #BranchMenuSmall .description {
        white-space: normal;
        text-align: left;
        margin-top: 0;
        flex-grow: 1;
    }

    #BranchMenuSmall h1 {
        width: 100%;
    }

    #BranchMenuSmall .arrow path {
        fill: #FFFFFF;
    }

    #BranchMenuSmall svg {
        margin: 0;
    }

    /*.sticky-wrapper {
        height: 120px;
    }*/
}