.App.demoPage {
    padding-right: 0;
    padding-bottom: 0;
}

.demoPage h1 {
    margin-right: 16px;
}

.demoblock {
    margin-bottom: 74px;
}

.demoblock h2 {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    display: none;
}

.demoblock:last-of-type h2 {
    display: initial;
}

.demoitem {
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
}

.demoitem a {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.demologo {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 16px;
    padding: 8px;
    margin-top: 2px;
}

.demologo img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.demoitem .text {
    margin-right: 12px;
}

.demoitem h4 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
}

.demoitem p {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
}

.arrow {
    width: 22px;
    margin-left: auto;
    margin-right: 16px;
    flex-grow: 0;
    flex-shrink: 0;
}

.purple .demologo, .BranchLogo.purple {
    background-color: #7268D8;
}

.red .demologo, .BranchLogo.red {
    background-color: #EF6650;
}

.yellow .demologo, .BranchLogo.yellow {
    background-color: #E0B12B;
}

.white .demologo, .BranchLogo.white {
    background-color: #FFFFFF;
}

.demoitem.purple:hover {
    background-color: rgba(114, 104, 216, 0.2);
}

.demoitem.red:hover {
    background-color: rgba(239, 102, 80, 0.2);
}

.demoitem.yellow:hover {
    background-color: rgba(224, 177, 43, 0.2);
}

.demoitem.white:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.demoitem.without-img {
    padding-top: 30px;
    padding-bottom: 30px;
}

.demoitem.without-img a {
    align-items: center;
}

.demoitem.without-img p {
    color: #F4F4F4;
    font-size: 20px;
    line-height: 28px;
    margin-right: 12px;
}

@media screen and (min-width: 481px) {
    .demoPage h1 {
        margin-right: 32px;
    }

    .demoblock h2 {
        display: block;
    }

    .demoitem {
        padding-bottom: 32px;
        padding-top: 32px;
    }

    .demoitem a {
        align-items: center;
    }

    .demologo {
        width: 62px;
        height: 62px;
        padding: 15px;
        margin-right: 44px;
    }

    .demoitem h4 {
        font-size: 30px;
        line-height: 40px;
    }

    .arrow {
        margin-right: 32px;
    }

    .demoitem.without-img {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

@media screen and (min-width: 1440px) {
    .demoPage h1 {
        margin-right: 0;
        margin-bottom: 68px;
    }

    .demoblock {
        display: flex;
    }

    .demoblock h2, .projectblock.branch h2 {
        flex: 0 0 30%;
    }

    .demoPage .demoblock h2 {
        margin-right: 32px;
    }

    .demoblock>div {
        flex: 0 100%;
    }

    .demologo {
        width: 80px;
        height: 80px;
        padding: 20px;
    }

    .arrow {
        margin-right: 13%;
    }
}