.researchers .demoblock {
    margin-right: 16px;
}

.researchers .demoblock h2 {
    display: block;
    margin-bottom: 36px;
}

.person {
    margin-bottom: 40px;
}

.person img {
    width: 60%;
    margin-bottom: 20px;
}

.researcherblock .person p {
    color: #9B9B9B;
    font-size: 16px;
    line-height: 30px;
    margin: 0;
    letter-spacing: normal;

}

.researcherblock .person .name {
    color: #F4F4F4;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
}

.person img, .person-small img, .imagecontainer img, .article img {
    mask-image: linear-gradient(134.53deg, #282828 0%, rgba(40,40,40,0.85) 59.51%, rgba(40,40,40,0) 100%);
    -webkit-mask-image: linear-gradient(134.53deg, #282828 0%, rgba(40,40,40,0.85) 59.51%, rgba(40,40,40,0) 100%);
}

.person-small {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-right: 15px;
    margin-left: 15px;
}

.person-small img {
    width: 100%;
    margin-bottom: auto;
}

.person-small p {
    opacity: 0.5;
    color: #F4F4F4;
    font-size: 16px;
    line-height: 30px;
}

.person-small p.name {
    opacity: 1;
    margin-top: 14px;
}

@media screen and (min-width: 481px) {
    .researchers .demoblock {
        width: 75%;
    }

    .researcherblock {
        /*display: flex;
        flex-flow: row wrap;
        justify-content: space-between;*/
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(27%, 1fr));
        grid-gap: 15px;
    }

    /*.researcherblock::after {
        content: "";
        flex-basis: 30%;
    }*/

    .person {
        flex: 0 30%;
        display: flex;
        flex-direction: column;
    }

    .person img {
        width: 100%;
    }

    .person p:nth-child(4) {
        word-break: break-all;
    }
}

@media screen and (min-width: 769px) {
    .researchers .demoblock {
        width: 100%;
    }

    .researchers .demoblock .big, .researchers .accolations {
        padding-right: 13%;
        flex: 0 60%;
    }

    .addressblock.researchers {
        padding: 0;
    }

    .addressblock.researchers>.researcherblock {
        padding-right: 13%;
        flex: 0 100%;
    }
}

@media screen and (min-width: 1024px) {
    .researcherblock {
        grid-column-gap: 6%;
        grid-row-gap: 0;
        grid-template-columns: repeat(3, minmax(25%, 1fr));
    }
}