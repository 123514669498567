* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: #282828;
    /*color: #ffffff;*/
    color: #F4F4F4;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    color: #E0B12B;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    /*overflow-y: auto;*/
}

#root > * {
    flex-shrink: 0;
}

a:hover {
    color: #EF6650;
}

a:active {
    color: #7268D8;
}

.App {
    /*padding-right: 16px;*/
    padding-left: 16px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding-bottom: 22px;
    flex-grow: 1;
}

.fixed > * {
    visibility: hidden;
}

#root.fixed {
    overflow: hidden;
}

.fixed #university-logo {
    display: none;
}

.fixed .sticky-wrapper {
    height: 100%;
}

.fixed nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    visibility: visible;
}

.title {
    color: #9B9B9B;
    font-size: 40px;
    font-weight: bold;
    line-height: 44px;
    margin-top: 39px;
    margin-bottom: 34px;
    padding-right: 16px;
}

p.big {
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
}

.notfound, .loading {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 40px 16px;
}

.main-page .loading {
    margin-right: 0;
    margin-left: 0;
}

.subpage {
    margin-right: 16px;
}

.subpage h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 15px;
}

.subpage p, .subpage li {
    margin-bottom: 25px;
    word-break: break-word;
    color: #F4F4F4;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 24px;
}

.subpage ul, .content ul {
    list-style-type: disc;
    list-style-position: inside;
}

.content ul, .content ol {
    margin-bottom: 15px;
    word-break: break-word;
    list-style-position: inside;
}

.content ul {
    text-align: left;
}

.content ul ul {
    margin-left: 20px;
}

.content h3, .content h4 {
    margin-bottom: 15px;
}

.cookie-container {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    align-items: center !important;
}

.button--cookie {
    height: 32px;
    width: 135px;
    border: 3px solid #7268D8;
    border-radius: 21px;
    padding: 0;
    background-color: #7268D8;
    font-size: 14px;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
    font-weight: bold;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 16px;
}

.text--cookie {
    margin: 0;
    width: 100% !important;
}

@media screen and (min-width: 768px) {
    .App {
        /*padding-right: 32px;*/
        padding-left: 32px;
    }

    .title {
        font-size: 80px;
        line-height: 80px;
        margin-top: 57px;
        margin-bottom: 42px;
        margin-right: 32px;
        padding-right: 0;
    }

    p.big {
        color: #FFFFFF;
        font-size: 20px;
        line-height: 28px;
    }

    p.big a {
        font-size: 20px;
    }

    .notfound, .loading {
        margin-left: 32px;
        margin-right: 32px;
    }

    .main-page .loading {
        margin-left: 0;
        margin-right: 0;
    }

    .subpage {
        margin-right: 32px;
    }

    /*.subpage p, .subpage ul {
        margin-bottom: 70px;
    }*/

    .subpage h3 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .subpage p, .subpage h3, .subpage ul {
        margin-right: 28%;
        margin-left: 21%;
    }

    .cookie-container {
        padding-left: 32px;
        padding-right: 32px;
    }

    .text--cookie {
        width: 75% !important;
    }

    .button--cookie {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1440px) {
    .App {
        /*padding-left: 13%;*/
        padding-left: 0;
        /*padding-right: 13%;*/
    }

    .title {
        padding-left: 13%;
        padding-right: 13%;
        margin-right: 0;
    }

    .small-title, .empty-title {
        padding-left: 13%;
        margin-right: 32px;
        flex: 0 0 30%;
    }

    .notfound, .loading, .subpage {
        margin-right: 13%;
        margin-left: 13%;
    }

    .main-page .loading {
        margin: 0;
    }

    .subpage p {
        margin-bottom: 70px;
    }

    .subpage {
        padding-top: 20px;
    }

    .cookie-container {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}