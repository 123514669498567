.description.branch {
    margin-right: 16px;
    margin-bottom: 36px;
}

.description.branch p {
    color: #F4F4F4;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
}

.description.branch a {
    font-size: 20px;
}

.description.branch ul {
    color: #F4F4F4;
    font-size: 20px;
    line-height: 28px;
    list-style-type: disc;
    list-style-position: inside;
}

.svgBranch {
    display: none;
}

.demoblock.branch, .projectblock.branch {
    margin-bottom: 53px;
}

.demoblock.branch h2 {
    display: block;
    color: #9B9B9B;
    font-size: 40px;
    font-weight: bold;
    line-height: 44px;
}

.branch h2, .small-branch h2 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 10px;
}

.projectblock .projectitem {
    padding: 22px 16px 22px 0;
}

.projectblock .projectitem h3, .small-branch h3 {
    font-size: 20px;
    line-height: 28px;
}

.projectblock .projectitem .description, .small-branch .projectitem .description {
    flex-flow: column nowrap;
    align-items: flex-start;
}

.projectblock .circle, .small-branch .circle {
    display: none;
}

.projectblock .arrow {
    margin: 18px 0 0 0;
}

.demoblock.collab.small-branch h2 {
    display: block;
    margin-bottom: 35px;
}

.addressblock.small-branch {
    margin-right: 16px;
}

.addressblock.small-branch h2 {
    display: none;
}

@media screen and (min-width: 481px) {
    .description.branch {
        margin-right: 32px;
    }

    .description.branch p, .description.branch ul {
        width: 80%;
    }

    .description.branch h1 {
        width: 100%;
    }

    .description.branch p {
        margin-bottom: 20px;
    }

    .branch h2, .demoblock.branch h2, .demoblock.small-branch h2, .addressblock.small-branch h2 {
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
        font-weight: 300;
    }

    .demoblock.branch {
        width: 100%;
    }

    .demoblock.branch .demologo {
        height: 80px;
        width: 80px;
        padding: 20px;
    }

    .demoblock.branch, .projectblock.branch {
        margin-bottom: 70px;
    }

    .demoblock.branch .demoitem:last-of-type, .projectblock .projectitem:last-of-type {
        border-bottom: 0;
    }

    .projectblock .projectitem {
        padding: 22px 0;
    }

    .projectblock .projectitem h3, .demoblock.small-branch .projectitem h3 {
        font-size: 24px;
        line-height: 32px;
        width: 80%;
    }

    .projectblock .projectitem .description, .small-branch .projectitem .description {
        flex-flow: row wrap;
        align-items: center;
    }

    .projectblock .circle, .small-branch .circle {
        display: inline-block;
    }

    .projectblock .arrow {
        margin: 0 32px 0 auto;
    }

    .demoblock.small-branch {
        width: 100%;
    }

    .demoblock.small-branch h2 {
        width: 90%;
    }

    .demoblock.small-branch .projectitem {
        padding-right: 15%;
    }

    .addressblock.small-branch {
        margin-right: 32px;
    }

    .addressblock.small-branch h2 {
        display: block;
        margin-bottom: 43px;
    }
}

@media screen and (min-width: 1440px) {
    .description.branch {
        display: block;
        margin-right: 0;
    }

    .description.branch>div {
        display: flex;
        margin-bottom: 66px;
    }

    .svgBranch {
        display: block;
        padding-left: 0;
        flex: 0 0 30%;
        padding-right: 5%;

    }

    .svgBranch svg {
        width: 100%;
        display: block;
    }

    .description.branch .big, .description.branch ul {
        padding-right: 13%;
    }

    .branch {
        display: flex;
    }

    .branch>div {
        width: 100%;
    }

    .projectblock .arrow {
        margin-right: 0;
    }

    .projectitem .description {
        padding-right: 13%;
    }

    .projectblock .projectitem {
        padding-top: 0;
    }

    .projectitem ~ .projectitem {
        padding-top: 22px;
    }
}

@media screen and (min-width: 1220px) {
    .description.branch .big, .description.branch ul {
        width: 60%;
    }
}